import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import SiteIntro from "../components/site-intro"
import AboutSection from "../components/about-section"
import WhyMeSection from "../components/why-me-section"
import HowSection from "../components/how-section"
import styled from "styled-components"

const Snap = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100vh;
  z-index: 0;
  scroll-snap-type: y mandatory;
  -webkit-scroll-snap-type: y mandatory;
  -moz-scroll-snap-type: y mandatory;
`

class AltPage extends React.PureComponent {
  state = {
    about: "",
    how: "",
    why: "",
  }

  componentDidMount() {
    const data = this.props.data.allWordpressPost.edges.reverse()
    const about = data.find(page => page.node.title === "About")
    const why = data.find(page => page.node.title === "Why Me")
    const how = data.find(page => page.node.title === "How I Do It")

    this.setState({ about, how, why }, () => console.log(this.state))
  }

  render() {
    return (
      <Layout page="Home" noFooter>
        <SEO title="Home" />
        <Snap>
          <SiteIntro />
          {this.state.about && (
            <>
              <AboutSection alt copy={this.state.about.node.content} />
              <WhyMeSection alt copy={this.state.why.node.content} />
              <HowSection alt copy={this.state.how.node.content} />
            </>
          )}
        </Snap>
      </Layout>
    )
  }
}

export default AltPage

export const aboutHome2 = graphql`
  query {
    allWordpressPost {
      edges {
        node {
          title
          content
        }
      }
    }
  }
`
